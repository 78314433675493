import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { EntityDescription } from '../../../entities/services/entities.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { DOCUMENT } from '@angular/common';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EstimatesService } from '../../services/estimates.service';
import { Subject } from 'rxjs';
import { ConfirmationPopupComponent } from 'app/shared/components/confirmation-popup.component';
import { ApiSettings, CalendarEventSettings, EstimatesSettings } from 'app/settings.class';

@Component({
  selector: 'con-new-survey',
  templateUrl: './new-survey.component.html',
  styleUrls: ['./new-survey.component.scss']
})
export class NewSurveyComponent implements OnInit, OnDestroy {
  private componentDestroyed$ = new Subject();
  public entityDescription: EntityDescription;
  public company: any;
  public prefill: any = {};
  public loading = true;
  public noDataMessage = 'No reports to display';
  public entityName: string;
  public lsName = 'recentCompanies';
  public companyWebsite = '';
  public companySurveys=  [];
  public prefillSurvey = null;
  public entitySurvey: any;
  public companyId: number;
  public isAPISyncEnabled = false;
  public isAPISyncLoading = false;
  public surveyCustomFields = [
    EstimatesSettings.SURVEY_STARTING_QUARTER_END_FIELD_CONFIG,
    EstimatesSettings.SURVEY_STARTING_ANNUAL_END_FIELD_CONFIG, 
    EstimatesSettings.TAXONOMY_NAME_FIELD_CONFIG
  ];
  public taxonomyNameGenerationConfig: any = {}
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private modalService: NgbModal,
    public estimatesService: EstimatesService,
    public reportService: CompanyReportsService
  ) { }

  ngOnInit() {
    this.route.params.pipe(
      tap((params: Params) => {
        this.companyId = params.id;
        this.reportService.getCompanyData(this.companyId).subscribe((companyDetails) => {
          this.companyWebsite = companyDetails.website;
          this.company = companyDetails;
          this.taxonomyNameGenerationConfig = EstimatesSettings.TAXONOMY_NAME_GENERATION_CONFIG;
          this.taxonomyNameGenerationConfig.format = this.taxonomyNameGenerationConfig.format.replace('{company_name}', this.company?.name);
        })
        this.estimatesService.getAPISyncStatus(this.companyId).pipe(take(1)).subscribe((response: any) => {
          this.isAPISyncEnabled = response
        })
      }),
      switchMap((params: Params) => {
        this.loading = true;
        return this.estimatesService.getCompanySurveys(params.id);
      }))
      .subscribe((companySurveys: any) => {
          this.companySurveys = companySurveys.data;
          this.companySurveys.map(item => item.hide= true);
          this.estimatesService.setCurrentCompanySurveys(this.companySurveys);
          if(this.companySurveys.length) {
            this.prefillSurvey = JSON.parse(JSON.stringify(this.companySurveys[0]));
            this.prefillSurvey.locked = false;
            if(this.prefillSurvey?.starting_annual) {
              this.prefillSurvey.starting_annual = this.prefillSurvey.starting_annual.toString();
            }
            this.prefillSurvey.quarters_forward = this.prefillSurvey.quarters_forward? this.prefillSurvey.quarters_forward : 3;
            this.prefillSurvey.annuals_forward = this.prefillSurvey.annuals_forward? this.prefillSurvey.annuals_forward : 4;

            this.prefillSurvey.currency_id = this.prefillSurvey.currency_id ? this.prefillSurvey.currency_id : null;
            this.entitySurvey = {
              currency_id: this.prefillSurvey && this.prefillSurvey.currency_id ? this.prefillSurvey.currency_id : null,
              currency: this.prefillSurvey && this.prefillSurvey.currency ? this.prefillSurvey.currency : null,
              taxonomy_id: this.prefillSurvey && this.prefillSurvey.taxonomy_id ? this.prefillSurvey.taxonomy_id : null,
              taxonomy: this.prefillSurvey && this.prefillSurvey.taxonomy ? this.prefillSurvey.taxonomy : null
            };
            this.loading = false;
          } else {
            this.setPrefillData();
          }

        },
        err => {
          if (err.status === 404) {
            this.router.navigate(['estimates', 'error'], { skipLocationChange: true });
          } else {
            this.toaster.error('Sorry, some error occurred!');
          }
          this.loading = false;
        });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  setPrefillData() {
    this.prefillSurvey = {
      accounting_standard: null,
      offset: null,
      starting_annual: '',
      starting_quarter: '',
      currency_id: null ,
      currency: null,
      quarters_forward:3,
      annuals_forward:4,
      locked: false
    }
    this.entitySurvey = {
      currency_id: null ,
      currency: null
    };

    this.reportService.getReports(true, +this.companyId).subscribe((data: any) => {
      let default_offset_month = 12;
      if(data?.company_reports.length) {
          const currentReport = JSON.parse(JSON.stringify(data.company_reports[0]));
          if(currentReport?.accounting_standard) {
            this.prefillSurvey.accounting_standard = currentReport.accounting_standard;
          }
          if(currentReport?.offset_month) {
            this.prefillSurvey.offset_month = currentReport.offset_month;
            default_offset_month =  this.prefillSurvey.offset_month;
          }
          if(currentReport?.currency && currentReport?.currency_id) {
            this.entitySurvey.currency_id = currentReport.currency_id;
            this.entitySurvey.currency = currentReport.currency;
            this.prefillSurvey.currency = currentReport.currency;
            this.prefillSurvey.currency_id = currentReport.currency_id;
          }
          const currentPeriodContext = this.getStartingAnnualsAndQuaters(default_offset_month);
          this.prefillSurvey.starting_annual = currentPeriodContext.starting_annual;
          this.prefillSurvey.starting_quarter = currentPeriodContext.starting_quarter;
      } else {
        const currentPeriodContext = this.getStartingAnnualsAndQuaters(default_offset_month);
        this.prefillSurvey.starting_annual = currentPeriodContext.starting_annual;
        this.prefillSurvey.starting_quarter = currentPeriodContext.starting_quarter;
      }
      this.loading = false;
    })
  }

  getStartingAnnualsAndQuaters(offset: number) {
    const date = new Date();
    const currentYear = date.getFullYear();
    const starting_annual = (currentYear - 1).toString();

    let month = (date.getMonth() - offset + 12) % 12;
    let starting_quarter_year = currentYear;
    if (month > date.getMonth()){
      starting_quarter_year--;
    }

    let quarter;
    if (month < 3) {
      quarter = 4;
      starting_quarter_year--;
    } else if (month < 6) {
      quarter = 1;
    } else if (month < 9) {
      quarter = 2;
    } else {
      quarter = 3;
    }

    const starting_quarter = `${starting_quarter_year}:Q${quarter}`;

    return {
      starting_annual: starting_annual,
      starting_quarter: starting_quarter
    };
  }

  getStripedWebsiteString(companyWebsite: string): string {
    if (companyWebsite) {
      return companyWebsite.replace(/(^\w+:|^)\/\//, '');
    }
    return '';
  }

  surveySaved(event: any) {
    this.estimatesService.getCompanySurveys(this.company.id).pipe(take(1)).subscribe((companySurveys: any) => {
      this.companySurveys = companySurveys.data;
      this.companySurveys.map(item => item.hide= true);
      this.estimatesService.setCurrentCompanySurveys(this.companySurveys);
    })
    if(event?.redirect_to_taxonomy?.id) {
      this.estimatesService.redirectedTaxonomy = event?.redirect_to_taxonomy;
      this.router.navigate(['estimates', this.company.id, 'estimate', event.id, 'survey-main']);
    }
  }

  toggleSurveyItem(survey) {
    survey.hide = !survey.hide;
  }
  getMutations(data) {
    return !data.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
  }

  openToggleConfirmModal(){
    this.isAPISyncEnabled = !this.isAPISyncEnabled;
    const modalRef = this.modalService.open(ConfirmationPopupComponent, { size: 'md' });
    const modalData = {
      title: `${!this.isAPISyncEnabled ? 'Disable' : 'Enable'} API sync`,
      text: `Are you sure you want to ${!this.isAPISyncEnabled ? 'disable' : 'enable'} API sync?`
     }
    modalRef.componentInstance.modalData = modalData;
    modalRef.result.then(() => {
      this.toggleAPISync();
    }, (reason) => {
      this.isAPISyncEnabled = !this.isAPISyncEnabled;
    });
  }

  toggleAPISync() {
    this.isAPISyncLoading = true;
    this.estimatesService.toggleAPISync(this.isAPISyncEnabled, this.company?.id).pipe(takeUntil(this.componentDestroyed$)).subscribe((response: any) => {
      this.toaster.success(`API sync ${this.isAPISyncEnabled ? 'enabled' : 'disabled'} successfully`);
      this.isAPISyncLoading = false;
    },
    err => {
      this.isAPISyncLoading = false;
      if(err?.data?.type === ApiSettings.RESPONSES.VALUE_ERROR && err?.data?.fields?.company_id) 
        this.toaster.error(err?.data?.fields?.company_id[0],`${this.isAPISyncEnabled ? 'Enable' : 'Disable'} API sync`);
      else
        this.toaster.error(`Failed to ${this.isAPISyncEnabled ? 'enable' : 'disable'} API sync`);
      this.isAPISyncEnabled = !this.isAPISyncEnabled;
    })
  }
}
