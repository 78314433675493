import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { ProfileService } from 'app/auth/services/profile.service';
import { CalendarService } from '../calendar.service';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import { EntitiesService, EntityDescription } from 'app/entities/services/entities.service';
import { ApiSettings, CalendarEventSettings } from 'app/settings.class';

@Component({
  selector: 'con-earnings-ai',
  templateUrl: './earnings-ai.component.html',
  styleUrls: ['./earnings-ai.component.scss']
})
export class EarningsAiComponent implements OnInit, OnDestroy {
  @Input() public companyDetails: any;
  @Input() private eventTypeId: number;
  @Output() eventCreated = new EventEmitter<any>();

  private componentDestroyed$ = new Subject<void>();
  private jsonChangeSubject = new Subject<string>();
  private defaultTimezone = '';
  private entityDescription: EntityDescription;

  public extractedDates: any[] = [];
  public formErrors: any = {};
  public isAllEventsSelected = false;
  public isCheckingForDuplicates = false;
  public isSavingEvents = false;
  public hasSelectedEvents = false;
  public hasDuplicates = false;
  public JSONData = '';
  public JSONErrors: string[] = [];
  public querySuggestions: string[] = [];
  public showAISection = false;

  public codeMirrorOptions: any = {
    mode: 'javascript',
    theme: 'idea',
    lineNumbers: true,
    lineWrapping: true,
    styleActiveLine: true,
    matchBrackets: true,
    autoCloseBrackets: true,
    indentUnit: 2,
    tabSize: 2,
    smartIndent: true,
  };

  constructor(
    private toaster: ToastrService,
    private profileService: ProfileService,
    private calendarService: CalendarService,
    private dateConverter: DateConverterService,
    private entityService: EntitiesService
  ) {}

  ngOnInit(): void {
    this.initializeQuerySuggestions();
    this.setupJSONDataListener();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private initializeQuerySuggestions(): void {
    const companyName = this.companyDetails?.name ?? 'the company';
    this.querySuggestions = [
      `When is Q1 2025 earnings report for ${companyName}? Return JSON: { "date": date, "Q": int, "year": int, "company": str, "source": str }.`,
      `Get all future earnings events for ${companyName}. Return as an array of JSON objects: { "date": date, "Q": int, "year": int, "company": str, "source": str }.`,
    ];
  }

  private setupJSONDataListener(): void {
    combineLatest([
      this.jsonChangeSubject.pipe(debounceTime(1000), distinctUntilChanged()),
      this.profileService.getUserTimezone(),
      this.entityService.getEntityDescriptionByEntityName(CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY),
    ])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([_, timezone, entityDescription]) => {
        this.defaultTimezone = timezone;
        this.entityDescription = entityDescription;
        this.processJSONData();
      });
  }

  public onJSONDataChange(): void {
    this.jsonChangeSubject.next(this.JSONData);
  }

  private processJSONData(): void {
    this.resetJSONState();
    if (!this.JSONData.trim()) return;
    try {
      const parsedData = JSON.parse(this.JSONData);
      const dataArray = Array.isArray(parsedData) ? parsedData : [parsedData];
      if (!dataArray.every(this.isValidEarningsObject)) {
        this.JSONErrors.push('One or more objects have invalid or missing date fields.');
        return;
      }
      this.checkDuplicateEvents(dataArray);
    } catch {
      this.JSONErrors.push('Invalid JSON format.');
    }
  }

  private resetJSONState(): void {
    this.JSONErrors = [];
    this.extractedDates = [];
    this.refreshSelectionState();
  }

  private isValidEarningsObject(item: any): boolean {
    return (
      typeof item === 'object' &&
      item !== null &&
      'date' in item &&
      moment(item.date, 'YYYY-MM-DD', true).isValid()
    );
  }

  private checkDuplicateEvents(eventsArray: any[]): void {
    this.isCheckingForDuplicates = true;
    this.codeMirrorOptions.readOnly = true;
    const payload = eventsArray.map(item => this.generateEventPayload(item));
    this.calendarService
      .checkDuplicateEvents({ events: payload }, 'bulk')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        response => {
          this.extractedDates = eventsArray.map((item, index) => ({
            ...item,
            duplicates: response?.message?.[index] || [],
            isSelected: response?.message?.[index]?.length === 0,
          }));
          this.handlePostDuplicateCheck();
        },
        () => {
          this.extractedDates = eventsArray;
          this.handlePostDuplicateCheck();
        }
      );
  }

  private handlePostDuplicateCheck(): void {
    this.isCheckingForDuplicates = false;
    this.codeMirrorOptions.readOnly = false;
    this.refreshSelectionState();
  }

  private refreshSelectionState(): void {
    const allSelected = this.extractedDates.length > 0 && this.extractedDates.every(item => item.isSelected || item.saved);
    this.isAllEventsSelected = allSelected;
    this.hasSelectedEvents = this.extractedDates.some(item => item.isSelected);
    this.hasDuplicates = this.extractedDates.some(item => item?.duplicates?.length > 0);
  }

  private generateEventPayload(event: any): any {
    return {
      ...this.calendarService.getPrefillValuesForReportDatesForm(this.entityDescription),
      owner_id: this.companyDetails?.id,
      calendar_event_type_id: this.eventTypeId,
      from_date: this.getDateInDefaultTimezone(event),
    };
  }

  private getDateInDefaultTimezone(event: any): string {
    return this.dateConverter.toEntityString(moment.tz(event.date, this.defaultTimezone));
  }

  public bulkSaveEvents(): void {
    const selectedEvents = this.extractedDates.filter(item => item.isSelected);
    if (selectedEvents.length === 0) return;

    this.isSavingEvents = true;

    const payload = selectedEvents.map(item => this.generateEventPayload(item));

    this.calendarService.bulkSaveEvents({ events: payload }, 'bulk').subscribe(
      () => {
        this.markSavedEvents(selectedEvents);
        this.toaster.success('Saved successfully!', 'Earnings events');
        this.eventCreated.emit();
      },
      error => {
        this.handleSaveError(error);
      }
    );
  }

  private markSavedEvents(events: any[]): void {
    events.forEach(item => {
      item.duplicates = [];
      item.saved = true;
      item.isSelected = false;
    });
    this.isSavingEvents = false;
    this.refreshSelectionState();
    this.isAllEventsSelected = false;
  }

  private handleSaveError(error: any): void {
    if (error.isValueError()) {
      this.formErrors = this.calendarService.getFlattendErrorArray(error.data);
    } else {
      this.toaster.error(ApiSettings.INTERNAL_SERVER_ERROR, 'Save earnings events');
    }
    this.isSavingEvents = false;
  }

  public selectEvent(event?: any): void {
    if (event) {
      event.isSelected = !event.isSelected;
    } else {
      this.toggleAllEventSelection();
    }
    this.refreshSelectionState();
  }

  private toggleAllEventSelection(): void {
    const selectAll = !this.isAllEventsSelected;
    this.extractedDates.forEach(item => {
      if (!item.saved) {
        item.isSelected = selectAll;
      }
    });
  }

  public updateExistingEvent(duplicateEvent: any, newEventDetails: any): void {
    const payload = {
      id: duplicateEvent?.id,
      from_date: this.getDateInDefaultTimezone(newEventDetails),
    };
    duplicateEvent.isUpdating = true;
    this.entityService
      .saveEntity(CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY, payload)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => this.handleUpdateSuccess(duplicateEvent, newEventDetails),
        error => this.handleUpdateError(duplicateEvent, error)
      );
  }

  private handleUpdateSuccess(duplicateEvent: any, newEventDetails: any): void {
    duplicateEvent.isUpdating = false;
    this.toaster.success('Updated successfully!', 'Earnings event');
    this.eventCreated.emit();
    newEventDetails.duplicates = [];
    newEventDetails.saved = true;
    this.refreshSelectionState();
  }

  private handleUpdateError(duplicateEvent: any, error: any): void {
    duplicateEvent.isUpdating = false;
    const errorMessage = error.isValueError()
      ? this.entityService.getFirstErrorForToaster(error?.data) || ApiSettings.INTERNAL_SERVER_ERROR
      : ApiSettings.INTERNAL_SERVER_ERROR;
    this.toaster.error(errorMessage, 'Update earnings event');
  }

  public expandAIsection(): void {
    this.showAISection = !this.showAISection;
  }

  public getMutations(event: boolean): string[] {
    return event ? ['fw', 'lg'] : ['fw', 'lg', 'rotate-90'];
  }

  public copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.toaster.success('Query copied to clipboard', '');
    });
  }
}
