import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntitiesService } from 'app/entities/services/entities.service';
import { EstimatesService } from 'app/estimates/services/estimates.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'con-unit-quantity-chooser',
  templateUrl: './unit-quantity-chooser.component.html',
  styleUrls: ['./unit-quantity-chooser.component.scss']
})
export class UnitQuantityChooserComponent implements OnInit, OnDestroy {

  @Input() moduleForm: FormGroup;

  private componentDestroyed$: Subject<boolean> = new Subject();

  public choosenData: { unit: any, quantity: any } = { unit: null, quantity: null };
  public entityDescriptions: { unit: any, quantity: any } = { unit: null, quantity: null };
  public selectionForm: FormGroup = new FormGroup({
    unit: new FormControl(null, [Validators.required]),
    quantity: new FormControl(null, [Validators.required])
  });

  constructor(
    private estimatesService: EstimatesService,
    private modalService: NgbModal,
    private entityService: EntitiesService
  ) { }

  ngOnInit(): void {
    this.fetchInitialInformation();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private fetchInitialInformation() {
    combineLatest([
      this.estimatesService.getConsensusValues(),
      this.entityService.getEntityDescriptionsByEntityNames(['Unit', 'Quantity'])
    ]).pipe(takeUntil(this.componentDestroyed$)).subscribe(([consensusData, entityDescriptions]: any) => {
      this.choosenData = {
        unit: consensusData?.unit || null,
        quantity: consensusData?.quantity || null
      }
      this.selectionForm.setValue({ ...this.choosenData });
      this.setModuleFormValue();
      this.entityDescriptions = {
        quantity: entityDescriptions[0],
        unit: entityDescriptions[1]
      }
    });
  }

  private setModuleFormValue() {
    this.moduleForm.get('item').setValue(
      {
        ...this.moduleForm.get('item').value,
        unit_id: this.choosenData?.unit?.id || null,
        quantity_id: this.choosenData?.quantity?.id || null
      });
  }

  public open(content: TemplateRef<any>) {
    this.modalService.open(content).result.then(
      () => {
        this.choosenData = this.selectionForm.value;
        this.setModuleFormValue();
      },
      () => {
        this.selectionForm.setValue({ ...this.choosenData });
      },
    );
  }

  public saveChoosenData(modal: NgbActiveModal) {
    this.selectionForm.markAllAsTouched();
    if (this.selectionForm.invalid) {
      return;
    }
    modal.close()
  }

  public onChoosingValue(type: string, value: any) {
    this.selectionForm.get(type).setValue(value);
  }
}
