<div class="alert ml-status-failed mb-0" *ngIf="taxonomyListingData === null && surveyEntity?.locked; else elseTaxonomySection">
  <con-icon-by-name iconName="warning"></con-icon-by-name> No taxonomy attached!
</div>
<ng-template #elseTaxonomySection>
    <div class="alert ml-status-in-progress mb-2" *ngIf="incompatibleTaxonomyWarning">
        <con-icon-by-name iconName="warning"></con-icon-by-name> The selected taxonomy contains incompatible modules for this company and cannot be attached. Please correct and create the taxonomy.
      </div>
  <con-loader *ngIf="isLoadingModules"></con-loader>
  <div class="card mb-15" *ngIf="taxonomyListingData">
    <div class="card-header">
      Taxonomy <ng-container *ngIf="surveryDate">for {{surveryDate}} survey</ng-container>
    </div>
    <div class="card-body">
      <div class="taxonomy-table">
        <div class="table-row table-head">
          <div class="table-cell">Name</div>
          <div class="table-cell">Locked</div>
          <div class="table-cell">Created At</div>
          <div class="table-cell">Updated At</div>
        </div>
        <div class="table-row">
          <div class="table-cell">{{taxonomyListingData.name}}</div>
          <div class="table-cell">{{taxonomyListingData.locked}}</div>
          <div class="table-cell"><con-date-shower [date]="taxonomyListingData?.created_at"></con-date-shower></div>
          <div class="table-cell"><con-date-shower [date]="taxonomyListingData?.updated_at"></con-date-shower></div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" *ngIf="showCreateMode || showEditMode">
    <div class="card-header">
      <span *ngIf='showCreateMode'>Create taxonomy</span> 
      <span *ngIf='showEditMode && !surveyEntity?.locked'>Edit taxonomy modules</span>
      <span *ngIf='showEditMode && surveyEntity?.locked'>View taxonomy modules</span>
    </div>
    <div class="card-body px-4">
      <con-loader *ngIf="isLoadingTaxonomy"></con-loader>
      <ng-container *ngIf="!isLoadingTaxonomy">
        <div class="d-flex justify-content-between align-items-end" *ngIf="!surveyEntity?.locked">
          <div class="form-group search-field mb-0" *ngIf="!selectedPrefill">
            <label class="form-label"><small>Choose taxonomy to {{showEditMode ? 'reset' : 'prefill'}}</small></label>
            <con-entity-search-field (onSelect)="showPrefilChangeConfirmModal($event)" entityName="Taxonomy" [fixedSearchParams]="{taxonomy_type:'Estimates'}" [fixedOptions]="fixedOptions" [isSmallSizeInput]="true" [showOnlyFixedoptions]="false" [labelKey]="'name'"></con-entity-search-field>
          </div>
          <div class="form-group search-field mb-0" *ngIf="selectedPrefill">
            <label class="form-label"><small>Prefilled taxonomy</small></label>
            <div class="alert alert-sm alert-success alert-dismissible mb-0" role="alert">
                {{selectedPrefill?.name}}
                <button type="button" class="close no-shadow" data-dismiss="alert" aria-label="Close" (click)="clearPrefillSelection()">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
          </div>
          <div class="pl-2">
            <button class="btn btn-primary" (click)="addParentModule()" *ngIf="!surveyEntity?.locked"> Add Parent Module </button>
          </div>
        </div>

        <div class="mt-2">
            <form [formGroup]="formArray" (ngSubmit)="saveCustomTaxonomy()">
                <div class="modules-table">
                    <div class="table-row p-2 table-head">
                        <div class="table-cell w-280px">Module</div>
                        <div class="table-cell ml-auto w-250px">Alias</div>
                        <div class="table-cell w-100px"></div>
                    </div>
                    <div [dragula]="surveyEntity?.locked ? null : 'parents'">
                        <ng-container *ngFor="let form of formArray.controls; let i = index;">
                            <div class="parent-section" [formGroupName]="i" [attr.parent-id]="i">
                                <div class="align-items-center">
                                    <div class="expand-btn">
                                        <button type="button" class="btn btn-sm no-shadow" *ngIf="form.controls.children.length" (click)="toggleChildrenDisplay(form)">
                                            <con-icon-by-name [iconName]="form.controls.expandChildren.value ? 'angle-down' : 'angle-right'"></con-icon-by-name>
                                        </button>
                                    </div>
                                    <div class="flex-1 draggable-row p-2 parentHandle">
                                        <div class="d-flex align-items-center parentHandle">
                                            <div class="table-cell w-280px">
                                                <ng-select class="w-100" formControlName="item" #selectDropdown placeholder="Select or add an item..."
                                                    notFoundText="Type to add value..." typeToSearchText="Type to add value..." (change)="updateAlias(form, $event)"
                                                    [compareWith]="compareFn" [clearable]="false"
                                                    [items]="nameOptions | filterSelected:selectedNames:form.get('item').value" bindLabel="name" bindValue="value"
                                                    [addTag]="form.get('allowTags')?.value ? addTag : null">
                                                </ng-select>
                                            </div>
                                            <div class="table-cell px-2" *ngIf="!form.value?.item?.module_id && form.value?.item?.name">
                                                <con-unit-quantity-chooser [moduleForm]="form"></con-unit-quantity-chooser>
                                            </div>
                                            <div class="table-cell ml-auto w-250px">
                                                <input formControlName="alias" [placeholder]="form?.value?.item?.name || 'Alias'" class="form-control">
                                            </div>
                                            <div class="table-cell w-100px d-flex justify-content-end">
                                                <button type="button" class="btn btn-primary mr-2" (click)="addChild(form)"><con-icon-by-name iconName="plus-circle"></con-icon-by-name></button>
                                                <button type="button" class="btn btn-danger" (click)="removeParent(i)"><con-icon-by-name iconName="minus-circle"></con-icon-by-name></button>
                                            </div>
                                        </div>
                                        <div class="d-block">
                                            <small *ngIf="form.errors" class="mat-error">{{form.errors[0]}}</small>
                                            <small *ngIf="form.value?.item?.incompatible" class="text-warning d-block">{{incompatibleError}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div [dragula]="surveyEntity?.locked ? null : 'children'" class="drop-area">
                                    <ng-container *ngIf="form.controls.expandChildren.value">
                                        <ng-container formArrayName="children" *ngFor="let childForm of form.controls.children.controls; let j = index;">
                                            <div class="child-section ml-4" [formGroupName]="j" [attr.child-id]="j">
                                                <div class="draggable-row p-2 childHandle">
                                                    <div class="d-flex align-items-center childHandle">
                                                        <div class="table-cell w-280px">
                                                            <ng-select class="w-100" formControlName="item" placeholder="Select or add an item..."
                                                                notFoundText="Type to add value..." typeToSearchText="Type to add value..."
                                                                (change)="updateAlias(childForm, $event)" [compareWith]="compareFn" [clearable]="false"
                                                                [items]="nameOptions | filterSelected:selectedNames:childForm.get('item').value" bindLabel="name"
                                                                bindValue="value" [addTag]="childForm.get('allowTags')?.value ? addTag : null">
                                                            </ng-select>
                                                        </div>
                                                        <div class="table-cell px-2" *ngIf="!childForm.value?.item?.module_id && childForm.value?.item?.name">
                                                            <con-unit-quantity-chooser [moduleForm]="childForm"></con-unit-quantity-chooser>
                                                        </div>
                                                        <div class="table-cell ml-auto w-250px">
                                                            <input formControlName="alias" [placeholder]="childForm?.value?.item?.name || 'Alias'" class="form-control">
                                                        </div>
                                                        <div class="table-cell w-100px d-flex justify-content-end">
                                                            <button type="button" class="btn btn-primary mr-2" (click)="addGrandchild(childForm)"><con-icon-by-name iconName="plus-circle"></con-icon-by-name></button>
                                                            <button type="button" class="btn btn-danger" (click)="removeChild(form, j)"><con-icon-by-name iconName="minus-circle"></con-icon-by-name></button>
                                                        </div>
                                                    </div>
                                                    <div class="d-block">
                                                        <small *ngIf="childForm.errors" class="mat-error">{{childForm.errors[0]}}</small>
                                                        <small *ngIf="childForm.value?.item?.incompatible" class="text-warning d-block">{{incompatibleError}}</small>
                                                    </div>
                                                </div>
                                                <div [dragula]="surveyEntity?.locked ? null : 'grandChildren'" class="drop-area">
                                                    <ng-container formArrayName="children" *ngFor="let grandchildForm of childForm.controls.children.controls; let k = index;">
                                                        <div class="grand-child-section ml-4" [formGroupName]="k" [attr.grandchild-id]="k">
                                                            <div class="draggable-row p-2">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="table-cell w-280px">
                                                                        <ng-select class="w-100" formControlName="item" placeholder="Select or add an item..."
                                                                            notFoundText="Type to add value..." typeToSearchText="Type to add value..."
                                                                            (change)="updateAlias(grandchildForm, $event)" [compareWith]="compareFn" [clearable]="false"
                                                                            [items]="nameOptions | filterSelected:selectedNames:grandchildForm.get('item').value" bindLabel="name"
                                                                            bindValue="value" [addTag]="grandchildForm.get('allowTags')?.value ? addTag : null">
                                                                        </ng-select>
                                                                    </div>
                                                                    <div class="table-cell px-2" *ngIf="!grandchildForm.value?.item?.module_id && grandchildForm.value?.item?.name">
                                                                        <con-unit-quantity-chooser [moduleForm]="grandchildForm"></con-unit-quantity-chooser>
                                                                    </div>
                                                                    <div class="table-cell ml-auto w-250px">
                                                                        <input formControlName="alias" [placeholder]="grandchildForm?.value?.item?.name || 'Alias'" class="form-control">
                                                                    </div>
                                                                    <div class="table-cell w-100px d-flex justify-content-end">
                                                                        <button type="button" class="btn btn-danger" (click)="removeGrandchild(childForm, k)"><con-icon-by-name iconName="minus-circle"></con-icon-by-name></button>
                                                                    </div>
                                                                </div>
                                                                <div class="d-block">
                                                                    <small *ngIf="grandchildForm.errors" class="mat-error">{{grandchildForm.errors[0]}}</small>
                                                                    <small *ngIf="grandchildForm.value?.item?.incompatible" class="text-warning d-block">{{incompatibleError}}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group mt-4">
                    <small for="taxonomy-name">Taxonomy name</small>
                    <input type="text" [formControl]="taxonomyName" placeholder="Custom Taxonomy Name" class="form-control w-250px">
                    <small *ngIf="taxonomyName.errors" class="mat-error">{{taxonomyName.errors.serverError}}</small>
                </div>
                <div *ngIf="!surveyEntity?.locked"> 
                    <button type="submit" class="btn btn-success mt-15" [disabled]="isSavingTaxonomy"> 
                        <ng-container *ngIf="isSavingTaxonomy">
                            <div class="spinner-border spinner-border-sm mr-1"></div>
                        </ng-container>
                        <span *ngIf="showCreateMode"> Create </span>
                        <span *ngIf="showEditMode"> Update </span>
                        Taxonomy
                    </button>
                </div>
            </form>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

