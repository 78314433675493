<div class="card my-4">
    <div class="card-header cursor-pointer" (click)="expandAIsection()">
        <div class="d-flex justify-content-start align-items-center">
            <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
            Process AI response
            <div class="card-header-toggle ml-auto">
                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(!showAISection)"></con-icon-by-name>
            </div>
        </div>
    </div>
    <div class="card-body" *ngIf="showAISection">
        Query suggestions
        <span [ngbTooltip]="'Click to copy the query and paste it in any AI tool'" [placement]="'right'">
            <con-icon-by-name iconName="info-circle ml-1"></con-icon-by-name>
        </span>
        <div class="query-suggestions mt-2">
            <span class="query-bubble" [ngbTooltip]="'Copy to clipboard'" *ngFor="let suggestion of querySuggestions"
                (click)="copyToClipboard(suggestion)">
                {{ suggestion }}
            </span>
        </div>
        <div class="row mt-4">
            <div class="col-md-6">
                <b>Paste the JSON data below</b>
                <div class="editor-container mt-1">
                    <ngx-codemirror #codemirror [options]="codeMirrorOptions" [(ngModel)]="JSONData" (ngModelChange)="onJSONDataChange()">
                    </ngx-codemirror>
                </div>
                <span *ngFor="let error of JSONErrors" class="mt-1 form-control-feedback">{{ error }}</span>
            </div>
            <div class="col-md-6">
                <b>Extracted events</b>
                <div class="events-table mt-1">
                    <div class="table-row table-head">
                        <div class="table-cell w-40px justify-content-center">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [id]="'check-all'" (click)="selectEvent()" [checked]="isAllEventsSelected" [disabled]="!extractedDates.length || isSavingEvents">
                                <label class="custom-control-label" [for]="'check-all'"></label>
                            </div>
                        </div>
                        <div class="table-cell w-60px justify-content-center">Sl. No.</div>
                        <div class="table-cell w-115px justify-content-center">Date</div>
                        <div class="table-cell flex-1" *ngIf="hasDuplicates">Duplicates <con-icon-by-name iconName="info-circle mr-1" class="ml-1" [ngbTooltip]="'Checks for duplicate events with dates falling within a ±6 week range from the new date'"></con-icon-by-name></div>
                    </div>
                    <con-no-data [message]="'Please paste a valid JSON response from AI tool to parse the events!'" [count]="extractedDates.length" *ngIf="!isCheckingForDuplicates"></con-no-data>
                    <con-loader *ngIf="isCheckingForDuplicates"></con-loader>
                    <ng-container *ngFor="let event of extractedDates; let index = index">
                        <div class="table-row" [ngClass]="{'alert-success text-dark': event?.saved}">
                            <div class="table-cell w-40px justify-content-center">
                                <div class="custom-control custom-checkbox" *ngIf="!event?.saved">
                                    <input type="checkbox" class="custom-control-input" [id]="'check-'+index" (click)="selectEvent(event)" [checked]="event.isSelected" [disabled]="isSavingEvents">
                                    <label class="custom-control-label" [for]="'check-'+index"></label>
                                </div>
                                <con-icon-by-name *ngIf="event?.saved" iconName="check" class="mr-1" [ngbTooltip]="'Event already saved!'"></con-icon-by-name>
                            </div>
                            <div class="table-cell w-60px justify-content-center">{{index+1}}</div>
                            <div class="table-cell w-115px justify-content-center">{{event?.date}}</div>
                            <div class="table-cell flex-1" *ngIf="hasDuplicates">
                                <div class="w-100" *ngIf="event?.duplicates.length">
                                    <con-icon-by-name iconName="exclamation-triangle" class="mr-1"></con-icon-by-name>
                                    <span class="duplicate-message">Similar event(s) already exists on the following dates:</span>                              
                                    <div class="duplicate-events-list">
                                        <div class="duplicate-event p-1" *ngFor="let duplicate of event?.duplicates">
                                            <div class="duplicate-event-details">
                                                <span class="w-80px"> <a [href]="'/entity/calendar_event/'+duplicate?.id" target="_blank">{{duplicate?.id}} </a> </span>
                                                <span class="flex-1"><con-date-shower [date]="duplicate?.from_date"></con-date-shower></span>
                                            </div>
                                            <button type="button" class="btn btn-primary btn-sm mr-1" [ngbTooltip]="'Update this event with the date '+event?.date" [placement]="'left'" (click)="updateExistingEvent(duplicate, event)" [disabled]="duplicate?.isUpdating">
                                                <ng-container *ngIf="!duplicate?.isUpdating; else buttonSpinner">
                                                    <con-icon-by-name iconName="pencil"></con-icon-by-name>
                                                </ng-container>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="general-errors" *ngIf="formErrors?.length">
                    <div class="alert alert-danger" *ngFor="let message of formErrors">
                        {{ message }}
                    </div>
                </div>
                <div class="table-actions mt-2" *ngIf="extractedDates.length">
                    <button class="btn btn-success export-selected" [disabled]="!hasSelectedEvents || isSavingEvents" (click)="bulkSaveEvents()">
                        <ng-container *ngIf="isSavingEvents">
                            <ng-container *ngTemplateOutlet="buttonSpinner"></ng-container>
                        </ng-container>
                        <span class="ml-1">Save selected events</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #buttonSpinner>
    <div class="spinner-border spinner-border-sm"></div>
</ng-template>