<div class="badge badge-light cursor-default">
    <span>{{choosenData?.unit?.name || '-'}} / {{choosenData?.quantity?.name || '-'}}</span>
    <button class="btn btn-sm" type="button" ngbTooltip="Edit unit and quantity"  (click)="open(formModal)"><con-icon-by-name iconName="pencil"></con-icon-by-name></button>
</div>

<ng-template #formModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Edit unit and quantity</h4>
		<button type="button" class="btn-close btn btn-sm no-shadow" aria-label="Close" (click)="modal.dismiss()"><con-icon-by-name iconName="close"></con-icon-by-name></button>
	</div>
	<div class="modal-body">
        <div class="mb-3">
            <label class="mb-1" for="unit"><small> Unit </small></label>
            <div class="alert alert-info alert-dismissible mb-0" role="alert" *ngIf="selectionForm.value?.unit">
                {{entityDescriptions?.unit?.getPrimaryString(selectionForm.value?.unit)}}
                <button type="button" class="close no-shadow" data-dismiss="alert" aria-label="Close" (click)="onChoosingValue('unit', null)">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <con-entity-search-field  *ngIf="!selectionForm.value?.unit" entityName="Unit" (onSelect)="onChoosingValue('unit', $event)"></con-entity-search-field>
            <con-form-errors [control]="selectionForm.get('unit')"></con-form-errors>
        </div>
        <div class="mb-3">
            <label class="mb-1" for="quantity"><small> Quantity </small></label>
            <div class="alert alert-info alert-dismissible mb-0" role="alert" *ngIf="selectionForm.value?.quantity">
                {{entityDescriptions?.quantity?.getPrimaryString(selectionForm.value?.quantity)}}
                <button type="button" class="close no-shadow" data-dismiss="alert" aria-label="Close" (click)="onChoosingValue('quantity', null)">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <con-entity-search-field *ngIf="!selectionForm.value?.quantity" entityName="Quantity" (onSelect)="onChoosingValue('quantity', $event)"></con-entity-search-field>
            <con-form-errors [control]="selectionForm.get('quantity')"></con-form-errors>
        </div>
	</div>
	<div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
		<button type="button" class="btn btn-primary" (click)="saveChoosenData(modal)">Save</button>
	</div>
</ng-template>