<ng-container *ngIf="isFetching; else earningsView">
    <con-loader></con-loader>
</ng-container>
<ng-template #earningsView>
    <con-earnings-ai [companyDetails]="companyDetails" [eventTypeId]="earningsTypeId" (eventCreated)="updateListing()"></con-earnings-ai>
    <ng-container *ngIf="!reportDatesPageError">
        <div class="row">
            <div class="col-md-6">
                <con-report-dates-form [companyDetails]="companyDetails" [eventTypeId]="earningsTypeId" (eventCreated)="updateListing()"></con-report-dates-form>
            </div>
        </div>
        <div class="alert alert-warning" role="alert">
            <con-icon-by-name iconName="exclamation-triangle"></con-icon-by-name>
            The table only shows events from {{filterThreshold?.months}} months ago ({{filterThreshold?.date}}) and all future events.
        </div>
        <div class="row white-bg mb-2">
            <div class="col-12">
                <div class="card">
                    <con-entity-list 
                        [columnsToDisplay]="['from_date', 'calendar_event_type.name', 'updated_at']"
                        [customColumnNames]="{ from_date: 'Date' }" 
                        [runUpdate]="refreshTable$" entityName="CalendarEvent"
                        [showSearchFields]="false" 
                        [fixedSearchParams]="tableFilter"
                        [sortFields]="{field: 'from_date', asc: false}">
                    </con-entity-list>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isFetching && reportDatesPageError">
        <div class="alert alert-danger">
          {{ reportDatesPageError }}
      </div>
    </ng-container>
</ng-template>