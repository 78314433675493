import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiSettings, EstimatesSettings } from '../../../../settings.class';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DragulaService } from 'ng2-dragula';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, of, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopupComponent } from 'app/shared/components/confirmation-popup.component';
import { EstimatesService } from 'app/estimates/services/estimates.service';

interface TaxonomySaveError {
  type: string;
  message: string;
  fields: {
    name?: string[];
    modules?: string[];
    survey_id?: string[];
  };
  general?: string[];
}

@Component({
  selector: 'con-custom-taxonomy',
  templateUrl: './custom-taxonomy.component.html',
  styleUrls: ['./custom-taxonomy.component.scss']
})
export class CustomTaxonomyComponent implements OnInit, OnDestroy {

  @ViewChildren('selectDropdown') selectDropdowns: QueryList<any>;
  @Input() surveyEntity: any;

  nameOptions: {name: string, value: any}[] = [];
  fixedOptions: any = EstimatesSettings.FIXED_TAXONOMY_OPTIONS
  formArray: FormArray = new FormArray([]);
  taxonomyName = new FormControl('');
  data = [];
  surveyId: number;
  oldIndex: number;
  oldChildIndex: number;
  oldGrandChildIndex: number;
  destroyed$ = new Subject();
  errorResponse: any;
  position = 1;
  showEditMode = false;
  showCreateMode = false;
  taxonomyListingData = undefined;
  completeModuleList: {name: string, value: any}[] = [];
  selectedNames: string[] = [];
  showPreLoader = false;
  surveryDate: string
  isLoadingModules = false;
  isLoadingTaxonomy = false;
  isSavingTaxonomy = false;
  selectedPrefill: any;
  initialFormValueBeforeEdit: any = [];
  incompatibleError = 'The selected module is incompatible.';
  incompatibleTaxonomyWarning = false;
  constructor(private http: HttpClient, private toaster: ToastrService, private route: ActivatedRoute, private dragulaService: DragulaService, private modalService: NgbModal, private estimatesService: EstimatesService) {
  }

  ngOnInit() {
    this.isLoadingModules = true;
    combineLatest([
      this.estimatesService.getAllEstimatesModules(this.surveyEntity.company_id), 
      this.route.params, 
      this.estimatesService.getCurrentCompanySurveys().pipe(switchMap((surveys) => {
        if(!surveys) {
          return this.estimatesService.getCompanySurveys(this.surveyEntity.company_id).pipe(take(1), map((res: any) => {
            this.estimatesService.setCurrentCompanySurveys(res.data);
            return res.data;
          }));
        }
        return of(surveys);
      }))
    ]).pipe(takeUntil(this.destroyed$)).subscribe(([moduleData, routeData, allSurveys]: [any, any, any]) => {
      this.completeModuleList = moduleData;
      this.surveryDate = this.surveyEntity.date;
      if (routeData.hasOwnProperty('id')) {
        this.surveyId = +routeData.id;
      }
      if (this.surveyEntity.taxonomy_id) {
        this.taxonomyListingData = this.surveyEntity.taxonomy;
        this.showEditForm();
      } else {
        this.taxonomyListingData = null;
        this.showCreateForm(allSurveys.filter(survey => survey?.taxonomy_id !== null)[0]?.taxonomy);
      }
      this.isLoadingModules = false;
    },
    (err) => {
      this.isLoadingModules = false;
      this.toaster.error(ApiSettings.INTERNAL_SERVER_ERROR);
    });
    this.configureDragula();
  }

  private configureDragula(): void {
    if(this.dragulaService.find('parents') === undefined) {
      this.dragulaService.createGroup('parents', {
        removeOnSpill: false,
        moves: function (el, container, handle) {
          return handle.classList.contains('parentHandle');
        }
      });
    }
    if(this.dragulaService.find('children') === undefined) {
      this.dragulaService.createGroup('children', {
        removeOnSpill: false,
        moves: function (el, container, handle) {
          return handle.classList.contains('childHandle');
        }
      });
    }
    if(this.dragulaService.find('grandChildren') === undefined) {
      this.dragulaService.createGroup('grandChildren', {
        removeOnSpill: false
      });
    }
    this.dragulaService.drop('parents').pipe(takeUntil(this.destroyed$)).subscribe(({ el, target, source, sibling }) => {
      this.onDropingParent(el, target);
    });
    this.dragulaService.drag('parents').pipe(takeUntil(this.destroyed$)).subscribe(({ name, el, source }) => {
      this.oldIndex = this.domIndexOf(el, source);
    });
    this.dragulaService.drop('children').pipe(takeUntil(this.destroyed$)).subscribe(({ el, target, source, sibling }) => {
      const targetParentId = +target.parentElement?.getAttribute('parent-id');
      const sourceParentId = +source.parentElement?.getAttribute('parent-id');
      if (!isNaN(targetParentId) && !isNaN(sourceParentId)) {
        this.onDropingChild(sourceParentId, targetParentId, this.oldChildIndex, this.domIndexOf(el, target));
      }
    });
    this.dragulaService.drag('children').pipe(takeUntil(this.destroyed$)).subscribe(({ name, el, source }) => {
      this.oldChildIndex = this.domIndexOf(el, source);
    });
    this.dragulaService.drop('grandChildren').pipe(takeUntil(this.destroyed$)).subscribe(({ el, target, source, sibling }) => {
      const targetGrandParentId = +target.parentElement?.parentElement?.parentElement?.getAttribute('parent-id');
      const sourceGrandParentId = +source.parentElement?.parentElement?.parentElement?.getAttribute('parent-id');
      const targetParentId = +target.parentElement?.getAttribute('child-id');
      const sourceParentId = +source.parentElement?.getAttribute('child-id');
      if (!isNaN(targetGrandParentId) && !isNaN(sourceGrandParentId) && !isNaN(targetParentId) && !isNaN(sourceParentId)) {
        this.onDropingGrandChild(sourceGrandParentId, sourceParentId, this.oldGrandChildIndex, targetGrandParentId, targetParentId, this.domIndexOf(el, target));
      }
    });
    this.dragulaService.drag('grandChildren').pipe(takeUntil(this.destroyed$)).subscribe(({ name, el, source }) => {
      this.oldGrandChildIndex = this.domIndexOf(el, source);
    });
  }
  
  onDropingParent(el, target) {
    const index = this.domIndexOf(el, target);
    const item = this.formArray.at(this.oldIndex);
    this.formArray.removeAt(this.oldIndex);
    this.formArray.insert(index, item);
    this.formArray.markAsDirty();
  }

  onDropingChild(fromParent: number, toParent: number, fromChild: number, toChild: number) {
    let droppedParent = this.formArray.controls[fromParent].get('children') as FormArray;
    let newParent = this.formArray.controls[toParent].get('children') as FormArray;
    const expandChildrenControl = (this.formArray.controls[toParent] as FormGroup).controls['expandChildren']
    expandChildrenControl.setValue(true);
    const movedControl = droppedParent.at(fromChild);
    droppedParent.removeAt(fromChild);
    if (fromParent === toParent) {
      droppedParent.insert(toChild, movedControl);
    } else {
      newParent.insert(toChild, movedControl);
    }
    this.formArray.markAsDirty();
  }

  onDropingGrandChild(fromParent: number, fromChild: number, fromGrandChild: number, toParent: number, toChild: number, toGrandChild: number) {
    const droppedParent = this.formArray.controls[fromParent].get('children') as FormArray;
    const droppedChild = (droppedParent.controls[fromChild].get('children') as FormArray);
    const newParent = this.formArray.controls[toParent].get('children') as FormArray;
    const newChild = (newParent.controls[toChild].get('children') as FormArray);
    const movedControl = droppedChild.at(fromGrandChild);
    droppedChild.removeAt(fromGrandChild);
    if (fromParent === toParent && fromChild === toChild) {
      droppedChild.insert(toGrandChild, movedControl);
    } else {
      newChild.insert(toGrandChild, movedControl);
    }
    this.formArray.markAsDirty();
  }

  showCreateForm(taxonomyToPrefill?: any) {
    this.showCreateMode = true;
    this.isLoadingTaxonomy = true;
    this.selectedPrefill = taxonomyToPrefill || this.fixedOptions[0];
    this.incompatibleTaxonomyWarning = !!this.estimatesService.redirectedTaxonomy?.id
    if(this.selectedPrefill?.is_fixed && !this.estimatesService.redirectedTaxonomy?.id) {
      this.getStandardTaxonomy(this.selectedPrefill?.id, false);
    } else {
      this.getExistingTaxonomyDetails(this.estimatesService.redirectedTaxonomy?.id || this.selectedPrefill?.id, false);
      this.taxonomyName.setValue(this.estimatesService.redirectedTaxonomy?.new_taxonomy_name || '');
      this.estimatesService.redirectedTaxonomy = null;
    }
  }
  //Handler for getting options.
  getOptions(data) {
    let opts = [];
    let recursiveFn = (data) => {
      data.map(item => {
        opts.push({name: item.name, value: item});
        if (Array.isArray(item.children)) {
          recursiveFn(item.children);
        }
      });
    }
    recursiveFn(data);
    return opts;
  }
  
  getStandardTaxonomy(moduleId: string, shouldRetainModules: boolean) {
    this.estimatesService.getStandardTaxonomyModules(moduleId).pipe(take(1)).subscribe((res: any) => {
      this.data = res;
      this.postFetchAPICallActions(shouldRetainModules);
    }, (err) => {
      this.isLoadingTaxonomy = false;
    })
  }

  getExistingTaxonomyDetails(moduleId: number, shouldRetainModules: boolean) {
    this.estimatesService.getTaxononyDetails(moduleId).pipe(take(1)).subscribe((res: any) => {
      this.selectedPrefill = this.selectedPrefill?.id ? { ...res, modules: undefined } : null
      this.data = res.modules;
      this.postFetchAPICallActions(shouldRetainModules);
    }, (err) => {
      this.isLoadingTaxonomy = false;
    })
  }

  postFetchAPICallActions(shouldRetainModules: boolean) {
    this.nameOptions = this.mergeUnique(this.getOptions(this.data), this.completeModuleList);
    if (shouldRetainModules) {
      this.estimatesService.retainModuleLineIds(this.initialFormValueBeforeEdit, this.data);
      this.formArray = this.createFormArray(this.data);
    } else {
      this.formArray = this.createFormArray(this.data);
      this.initialFormValueBeforeEdit = this.estimatesService.flattenFormArrayValue(this.formArray.value);
    }
    this.refreshDropDownList();
    this.isLoadingTaxonomy = false;
  }

  createFormArray(data: any[]): FormArray {
    const createFormGroup = (item, isCompatible) => new FormGroup({
      item: new FormControl({...item, incompatible: !isCompatible}),
      alias: new FormControl(item.alias && item.alias !== item.name ? item.alias : ''),
      allowTags: new FormControl(this.showCreateMode),
      children: new FormArray([]),
      expandChildren: new FormControl(item.children && item.children.length > 0)
    });

    const formArray = new FormArray(data.map(item => {
      const isParentCompatible = this.nameOptions.some(option => option.value.type === item.type && option.value.module_id === item.module_id && !option.value.incompatible);
      const formGroup = createFormGroup(item, isParentCompatible);

      if (item.children) {
        const childrenArray = formGroup.get('children') as FormArray;
        item.children.forEach(child => {
          const isChildCompatible = this.nameOptions.some(option => option.value.type === child.type && option.value.module_id === child.module_id && !option.value.incompatible);
          const childFormGroup = createFormGroup(child, isChildCompatible);

          if (child.children) {
            const grandchildrenArray = childFormGroup.get('children') as FormArray;
            child.children.forEach(grandchild => {
              const isGrandchildCompatible = this.nameOptions.some(option => option.value.type === grandchild.type && option.value.module_id === grandchild.module_id && !option.value.incompatible);
              const grandchildFormGroup = createFormGroup(grandchild, isGrandchildCompatible);
              grandchildrenArray.push(grandchildFormGroup);
            });
          }

          childrenArray.push(childFormGroup);
        });
      }
      return formGroup;
    }));

    if (this.surveyEntity?.locked) {
      formArray.disable();
      this.taxonomyName.disable();
    }

    return formArray;
  }
  getLevel(formGroup: FormGroup) {
    let level = 0;
    let currentGroup = formGroup;

    // Increase the level while parent exists
    while (currentGroup.parent) {
      level++;
      currentGroup = currentGroup.parent as FormGroup;
    }

    return level;
  }
  addChild(formGroup: FormGroup) {
    let childrenArray = formGroup.get('children') as FormArray;

    if (!childrenArray) {
      childrenArray = new FormArray([]);
      formGroup.addControl('children', childrenArray);
    }
    const defautAddChildItem = {
      alias: "",
      module_id: null,
      name: "",
      type: formGroup.value.item.type,
      position: "",
      unique_id: null,
      line_id: null
    }
    const newChildGroup = new FormGroup({
      item: new FormControl(defautAddChildItem),
      alias: new FormControl(''),
      children: new FormArray([]),
      expandChildren: new FormControl(true),  // Set initial value to true for expanding by default
      allowTags: new FormControl(true)      // Add allowTags property to enable tags for new rows
    });
    formGroup.get('expandChildren').setValue(true);

    childrenArray.push(newChildGroup);
    this.formArray.markAsDirty();
  }
  removeChild(formGroup: FormGroup, childIndex: number) {
    const childrenArray = formGroup.get('children') as FormArray;
    let flattenedItems = this.extractItemsFormValue(childrenArray.controls.at(childIndex).value);
    this.appendRemovedItemsToNameOptions(flattenedItems);
    setTimeout(() => {
      this.refreshDropDownList();
    })
    childrenArray.removeAt(childIndex);
    this.formArray.markAsDirty();
  }
  removeParent(parentIndex: number) {
    let flattenedItems = this.extractItemsFormValue(this.formArray.controls.at(parentIndex).value);
    this.appendRemovedItemsToNameOptions(flattenedItems);
    setTimeout(() => {
      this.refreshDropDownList();
    })
    this.formArray.removeAt(parentIndex);
    this.formArray.markAsDirty();
  }
  removeGrandchild(formGroup: FormGroup, grandchildIndex: number) {
    const grandchildrenArray = formGroup.get('children') as FormArray;
    let flattenedItems = this.extractItemsFormValue(grandchildrenArray.controls.at(grandchildIndex).value);
    this.appendRemovedItemsToNameOptions(flattenedItems);
    setTimeout(() => {
      this.refreshDropDownList();
    })
    grandchildrenArray.removeAt(grandchildIndex);
    this.formArray.markAsDirty();
  }
  appendRemovedItemsToNameOptions(flattenedItems) {
    flattenedItems.forEach(item => {
      let newItem;

      // If item contains only a 'name' key
      if (Object.keys(item).length === 1 && item.hasOwnProperty('name')) {
        // pass - we may need this logic later to check newly added item
      } else {
        // Otherwise, convert the 'item' into the desired format.
        newItem = {
          name: item.name,
          value: item
        };
        // Check if an item with the same name already exists in this.nameOptions
        let itemExists = this.nameOptions.some(option => option.name === newItem.name);
        // If an item with the same name does not exist, then add the new item
        if (!itemExists) {
          this.nameOptions.push(newItem);

        }
      }
    })
    this.nameOptions = [... this.nameOptions];
  }
  extractItemsFormValue(dataObj) {
    let items = [];

    // Checking if an object has item and pushing it to items array
    if (dataObj.hasOwnProperty('item')) {
      items.push(dataObj.item);
    }

    // If 'children' exists and is an array.
    if (Array.isArray(dataObj.children)){
      dataObj.children.forEach(child => {
        // Recursively find items in children and concatenate the results.
        items = items.concat(this.extractItemsFormValue(child));
      });
    }

    return items;
  }

  addGrandchild(formGroup: FormGroup) {
    let grandchildrenArray = formGroup.get('children') as FormArray;

    if (!grandchildrenArray) {
      grandchildrenArray = new FormArray([]);
      formGroup.addControl('children', grandchildrenArray);
    }
    const defautAddGrandChildItem = {
      alias: "",
      module_id: null,
      name: "",
      type: formGroup.value.item.type,
      position: "",
      line_id: null,
      unique_id: null
    }
    const newGrandchildGroup = new FormGroup({
      item: new FormControl(defautAddGrandChildItem),
      alias: new FormControl(''),
      allowTags: new FormControl(true),  // Add allowTags property to enable tags for new rows
    });

    grandchildrenArray.push(newGrandchildGroup);
  }

  addParentModule() {
    const defaultParentItem = {
      alias: "",
      module_id: null,
      name: "",
      type: EstimatesSettings.DERIVED_MODULES_DEFAULT_TYPE,
      position: "",
      unique_id: null,
      line_id: null
    }
    const newChildGroup = new FormGroup({
      item: new FormControl(defaultParentItem),
      alias: new FormControl(''),
      children: new FormArray([]),
      expandChildren: new FormControl(true),  // Set initial value to true for expanding by default
      allowTags: new FormControl(true)      // Add allowTags property to enable tags for new rows
    });

    this.formArray.push(newChildGroup);
    setTimeout(() => {
      this.selectDropdowns.last.focus();
    }, 10)
  }
  mapFormValueToModules(formValue: any[]): any[] {
    const modules: any[] = [];
    if(formValue) {
      formValue.forEach((formItem) => {
        const module: any = {
          alias: formItem.alias,
          type: formItem.item.type? formItem.item.type : EstimatesSettings.DERIVED_MODULES_DEFAULT_TYPE,
          position: formItem.item.position,
          name: formItem.item.name,
          module_id: formItem.item.module_id ? formItem.item.module_id : null,
          unit_id: formItem.item.unit_id ? formItem.item.unit_id : undefined,
          quantity_id: formItem.item.quantity_id ?  formItem.item.quantity_id: undefined,
          children: this.mapFormValueToModules(formItem.children)
        };

        if (this.showEditMode) {
          module.line_id = formItem.item.line_id
        }

        modules.push(module);
      });
    }

    return modules;
  }
  // position key reset using a Depth-First-Search (DFS)
  resetPositions(arr: any[]) {
    arr.forEach((node) => {
      // Assign the updated position to the current node
      node.position = String(this.position);
      this.position++;

      // Recursively update position for all children of the current node
      if (node.children && node.children.length > 0) {
        this.resetPositions(node.children);
      }
    });
  }
  // Reset FormArray positions
  resetFormArrayPositions(formArray: FormArray) {
    formArray.controls.forEach((formGroup: FormGroup, index) => {
      // Reset the position of the current formGroup
      const item = formGroup.get('item').value;
      item.position = String(this.position);
      this.position++;
      // Recursively reset position for all children of the current formGroup
      let childrenArray = formGroup.get('children') as FormArray;
      if (childrenArray && childrenArray.length > 0) {
        this.resetFormArrayPositions(childrenArray);
      }
    });
  }

  validateIncompatibleItems(formArray: FormArray): boolean {
    let isValid = true;
    formArray.controls.forEach((formGroup: FormGroup) => {
      const item = formGroup.get('item').value;
      if (item.incompatible) {
        isValid = false;
      }
      let childrenArray = formGroup.get('children') as FormArray;
      if (childrenArray && childrenArray.length > 0) {
        if (!this.validateIncompatibleItems(childrenArray)) {
          isValid = false;
        }
      }
    });
    return isValid;
  }
  saveCustomTaxonomy() {
    if(!this.validateIncompatibleItems(this.formArray)){
      this.taxonomyName.setErrors({serverError: 'Incompatible modules are present in the taxonomy.'});
      return
    }
    this.position = 1;
    let modules = this.mapFormValueToModules(this.formArray.value);
    this.resetPositions(modules);
    this.position = 1;
    this.resetFormArrayPositions(this.formArray);
    let postData  = {
      name: this.taxonomyName.value,
      survey_id:this.surveyId,
      modules: modules
    }
    const callUpdateAPI = !!(this.showCreateMode && !this.selectedPrefill?.is_fixed && this.selectedPrefill?.id && !this.formArray.dirty)
    this.isSavingTaxonomy = true;
    this.displayFormErrors(this.formArray.controls, null);
    if(this.showCreateMode && !callUpdateAPI) {
      this.http.post(ApiSettings.BASE_URL + `/estimates/taxonomy/`, postData).pipe(take(1)).subscribe((res: any) => {
        this.toaster.success("Taxonomy created successfully", "Custom Taxonomy");
        this.postTaxonomySaveActions(res);
        this.estimatesService.getCurrentSelectedSurvey().pipe(take(1)).subscribe((survey) => {
          this.estimatesService.setCurrentSelectedSurvey({...survey, taxonomy_id: res.id, taxonomy: res}, true);
        })
      }, (err) => {
        this.toaster.error("Taxonomy creation failed", "Custom Taxonomy")
        if(err.data) {
          this.errorResponse = err.data;
          if( this.errorResponse.fields &&  this.errorResponse.fields.hasOwnProperty('name')){
            this.taxonomyName.setErrors({serverError:  this.errorResponse.fields.name[0]});
          }
          this.displayFormErrors(this.formArray.controls, err.data);
        }
        this.isSavingTaxonomy = false;
      })
    }
    else {
      this.http.patch(ApiSettings.BASE_URL + `/estimates/taxonomy/${this.taxonomyListingData?.id || this.selectedPrefill?.id}`, postData).pipe(take(1)).subscribe((res: any) => {
        this.toaster.success("Taxonomy updated successfully", "Custom Taxonomy");
        this.postTaxonomySaveActions(res);
      }, (err) => {
        if (err.isValueError()) {
          this.toaster.error("Taxonomy update failed", "Custom Taxonomy")
          this.errorResponse = err.data;
          if( this.errorResponse.fields &&  this.errorResponse.fields.hasOwnProperty('name')){
            this.taxonomyName.setErrors({serverError:  this.errorResponse?.fields?.name[0]});
          }
          this.displayFormErrors(this.formArray.controls, err.data);
        } else {
          this.toaster.error(ApiSettings.INTERNAL_SERVER_ERROR)
        }
        this.isSavingTaxonomy = false;
      })
    }
  }

  postTaxonomySaveActions(res: any) {
    this.isSavingTaxonomy = false;
    this.showEditMode = false;
    this.showCreateMode = false;
    this.errorResponse = null;
    this.taxonomyListingData = res;
    this.surveyEntity.taxonomy = this.taxonomyListingData;
    this.surveyEntity.taxonomy_id = res.id;
    this.taxonomyName.setErrors(null);
    this.estimatesService.setConsensusValues(null);
    this.selectedPrefill = null;
    this.showEditForm();
  }


  toggleChildrenDisplay(formGroup: FormGroup) {
    const expandChildren = formGroup.get('expandChildren') as FormControl;
    expandChildren.setValue(!expandChildren.value);
  }

  domIndexOf(child, parent) {
    return Array.prototype.indexOf.call(parent.children, child);
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  transformToReadableFormat(responseObj: any): string {
    let readableErrors: string[] = [];
    for (let prop in responseObj) {
      if (Array.isArray(responseObj[prop])) {
        readableErrors = [...readableErrors, ...responseObj[prop]];
      }
    }
    return readableErrors.join(', ');
  }
  displayFormErrors(formGroups, errors: TaxonomySaveError = null) {
    formGroups.forEach((formGroup: FormGroup) => {
      if (errors) {
        const position = formGroup.get('item').value.position;
        const fieldErrors = errors.fields[position];
        if (fieldErrors) {
          let readableError = this.transformToReadableFormat(fieldErrors);
          formGroup.setErrors([readableError]);
        }
      } else {
        formGroup.setErrors(null);
      }
      let childrenArray = formGroup.get('children') as FormArray;
      if (childrenArray && childrenArray.length > 0) {
        this.displayFormErrors(childrenArray.controls, errors);
      }
    });
  }
  addTag = (name) => {
    return { name: name, value: {name: name} };
  };
  onAddItem(formControl: FormControl, name: string) {
    // Assuming this is new item
    const newItem = { name: name, value: name };

    // Add the new item to nameOptions array
    this.nameOptions.push(newItem);

    // Set the new item into the form control
    formControl.setValue(newItem);
  }
  compareFn(a, b) {
    return a && b ? a.name === b.name : a ===b;
  }

  showEditForm() {
    this.incompatibleTaxonomyWarning = false;
    this.showEditMode = true;
    this.isLoadingTaxonomy = true;
    this.estimatesService.getTaxononyDetails(this.taxonomyListingData.id).pipe(take(1)).subscribe((res: any) => {
      this.data = res.modules;
      this.taxonomyName.setValue(res.name);
      this.postFetchAPICallActions(false);
    }, (err) => {
      this.isLoadingTaxonomy = false;
      this.toaster.error('Failed to fetch taxonomy details');
    })
  }
  mergeUnique(nameOptions, completeModuleList) {
    const combined = [...nameOptions, ...completeModuleList];
  
    return combined.reduce((acc, item) => {
      const exist = acc.some(existItem => existItem.value.type === item.value.type && existItem.value.module_id === item.value.module_id);
  
      if (!exist) {
        // Check if the item exists only in nameOptions
        const foundInNameOptions = nameOptions.some(
          module => module.value.type === item.value.type && module.value.module_id === item.value.module_id
        );
        const foundInCompleteModules = completeModuleList.some(
          module => module.value.type === item.value.type && module.value.module_id === item.value.module_id
        );
  
        // If it is in nameOptions but not in completeModuleList, mark it as incompatible
        if (foundInNameOptions && !foundInCompleteModules) {
          acc.push({ ...item, value: { ...item.value, incompatible: true } });
        } else {
          acc.push({ ...item, value: { ...item.value, incompatible: false } });
        }
      }
      return acc;
    }, []);
  }
  
  filterExistingModuleItems() {
    this.completeModuleList = this.completeModuleList.filter((module) =>
      !this.getOptions(this.data).some((option) => option.name == module.name)
    );
  }
  convertArrayToNameOptionsType(arr: any[]): any[] {
    return arr.map(item => ({
      name: item.name,
      value: item
    }));
  }

  updateAlias(fc, data) {
    this.refreshDropDownList();
    fc.get('alias').setValue(data.value.alias);
    const existingLineId = this.estimatesService.findLineIdByModuleDetails(this.initialFormValueBeforeEdit, data.value);
    if (existingLineId) {
      const currentControlValue = fc.value;
      fc.get('item').setValue({...currentControlValue.item, line_id: existingLineId});
    }
  }
  getNamesFromTree(data) {
    let names = [];

    data.forEach(element => {
      names.push(element.name);

      if (element.children) {
        names = names.concat(this.getNamesFromTree(element.children));
      }
    });

    return names;
  }

  refreshDropDownList() {
    let modules = this.mapFormValueToModules(this.formArray.value);
    this.selectedNames = this.getNamesFromTree(modules);
    this.nameOptions = this.nameOptions.filter((option) => {
      if (option.value.incompatible) {
        return this.selectedNames.includes(option.name);
      }
      return true;
    });
  }

  showPrefilChangeConfirmModal(event: any) {
    if (this.formArray.dirty || this.showEditMode) {
      const modalRef = this.modalService.open(ConfirmationPopupComponent, { size: 'md' })
      const modalData = {
        title: 'Are you sure?',
        text: this.showEditMode ? `Resetting to a new taxonomy will discard all changes made to the current taxonomy. Do you want to continue?` : `Any changes you have made will be lost. Do you want to continue?`,
      };
      modalRef.componentInstance.modalData = modalData;
      modalRef.result.then(() => {
        this.onChoosingExistingTaxonomy(event, this.showEditMode);
      }, (reason) => {
      });
    } else {
      this.onChoosingExistingTaxonomy(event, this.showEditMode);
    }
  }

  onChoosingExistingTaxonomy(event: any, shouldRetainModules: boolean) {
    this.selectedPrefill = event;
    this.isLoadingTaxonomy = true;
    if (event.hasOwnProperty('is_fixed') && event?.is_fixed) {
      this.getStandardTaxonomy(event.id, shouldRetainModules);
    } else {
      this.getExistingTaxonomyDetails(event.id, shouldRetainModules);
    }
  }

  clearPrefillSelection() {
    this.selectedPrefill = null
  }

}
